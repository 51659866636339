body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.batman {
/*  background-color: #212322;*/
  background-color: #000;
  color: #FFFFFF;
}
html,
body.noheadfixfoot,
body.noheadfixfoot #root,
body.noheadfixfoot .App {
  height: 100%;
}

a.link,
.link,
a:hover {
  text-decoration: none;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.max-width-650 {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
.align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

h1 {
  font-size: 55px;
  font-weight: 600;
}
h2 {
  font-size: 45px;
  font-weight: 600;
}
h3 {
  font-size: 35px;
  font-weight: 600;
}
h3.second {
  color: #006838;
}
h4 {
  font-size: 31px;
  font-weight: 600;
}
.p, .li {
  color: #566171;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: .01em;
}
.p.lg {
  font-size: 20px;
  line-height: 28px;
}
.p.md {
  font-size: 20px;
  line-height: 32px;
}
body.batman .p {
  color: #A0AABA;
}

a.button,
.button {
  display: inline-block;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding: 12px 20px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  background-color: #006838;
  -webkit-transition: 150ms;
  transition: 150ms;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
}
a.button.sm,
.button.sm {
  padding: 8px 12px;
  font-size: 14px;
}
a.button:hover,
.button:hover {
  background-color: #32815d;
}
a.button:focus,
.button:focus {
  box-shadow: none;
}
a.button:active,
.button:active,
.btn-primary:not(:disabled):not(.disabled):active:active {
  background-color: #006838;
  border-color: none;
}
a.button.outline,
.button.outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #bbc2ce;
  color: #23262d;
}
a.button.outline:hover,
.button.outline:hover {
  color: #006838;
  background: #ffffff;
  box-shadow: inset 0px 0px 0px 1px #006838;
}
a.button.outline:active,
.button.outline:active,
.btn-primary.outline:not(:disabled):not(.disabled):active:active {
  background-color: #fff;
  color: #23262d;
  border-color: none;
}
body.batman a.button.outline,
body.batman button.outline {
  color: #FFFFFF;
  background: transparent;
  box-shadow: inset 0px 0px 0px 1px #566171;
}
body.batman a.button.outline:hover,
body.batman .button.outline:hover {
  color: #006838;
  background: #212322;
  box-shadow: inset 0px 0px 0px 1px #006838;
}
.sec-bg-light {
  background-color: #fafafa;
}
.sec-bg-dark {
  background-color: #2c2e2e;
}



@media screen and (max-width: 991px) {
  h1 {
    font-size: 47px;
    line-height: 51px;
  }
  h2 {
    font-size: 37px;
    line-height: 45px;
  }
  h3 {
    font-size: 33px;
  }
  h4 {
    font-size: 28px;
  }
  .p.lg {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 35px;
    line-height: 42px;
  }
  h2 {
    font-size: 33px;
    line-height: 35px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 26px;
  }
  .p.lg {
    font-size: 18px;
    line-height: 26px;
  }
}













