.Footer {
  font-size: 14px;
  padding-top: 30px;
  padding-bottom: 10px;
}
.Footer,
.Footer a {
  color: #566171
}
body.batman .Footer,
body.batman .Footer a,
body.company .Footer,
body.company .Footer a {
  color: #A0AABA;
}

.Footer .list-inline-item:not(:last-child) {
  margin-right: 1rem;
}

.Footer .left {
  text-align: left;
}
.Footer .right {
    text-align: right;
  }
.Footer .geedop-logo {
  width: 30px;
}

@media (max-width: 575px) {
  .Footer .left {
    text-align: center;
  }
  .Footer .right {
    text-align: center;
  }
}