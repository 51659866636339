.App {
  position: relative;
}
.App.mod {
  overflow: hidden;
}
.App .main {
  padding-bottom: 6rem;
  min-height: 500px;
  padding-top: 50px;
}
.App .logomain {
  display: inline-block;
  cursor: pointer;
}
.App .logomain img {
  width: 125px;
}
.App .nav-bar {
  margin: 0;
  width: auto;
  padding: 0 15px;
  height: 50px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
body.batman .App .nav-bar {
  background-color: rgba(0, 0, 0, 0.9);
}
body.batman .App .nav-bar.noopac {
  background-color: transparent;
   backdrop-filter: none;
  -webkit-backdrop-filter: none;
}
.App .nav-bar .nav-item-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.App .nav-bar .nav-item,
.mob-nav-bar .nav-item {
  padding: 12px 15px;
  color: #23262d;
  font-size: 14px;
  font-weight: 400;
}
body.batman .App .nav-bar .nav-item,
body.batman .mob-nav-bar .nav-item {
  color: #ffffff;
}
.App .nav-links {
  margin-bottom: 0;
  padding-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
}
.App .nav-right {
  display: inline-block;
  width: 128px;
  text-align: right;
  padding-right: 5px;
  color: #23262d;
}
body.batman .App .nav-right {
  color: #fff;
}
.App .nav-right .lang,
.mob-nav-bar .lang {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.App .nav-right .lang:hover,
.mob-nav-bar .lang:hover {

}
.App .nav-right .icon {
  font-size: 20px;
}

.mob-nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  bottom: 0;
  background-color: #fff;
  z-index: 13;
  padding: 1rem 1rem 2rem 2rem;
  box-shadow: 0 10px 20px 0 rgba(76,98,118,.15);
  overflow-y: scroll;
}
body.batman .mob-nav-bar {
  background-color: #212322;
  color: #FFFFFF;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 60%);
}
.mob-nav-bar .mob-nav-links {
  padding-left: 0;
}
.mob-nav-bar-backd {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  z-index: 12;
  opacity: 0.3;
}
.mob-nav-bar .nav-item-wrap {
  list-style: none;
  margin: 12px 0;
}
.mob-nav-bar .nav-item {
  padding: 0;
}
.mob-nav-right {
  display: none;
}
.mob-nav-bar .closeHead {
  text-align: right;
  overflow: auto;
  font-size: 30px;
  line-height: 0;
}
.mob-nav-bar .closeHead .closex {
  cursor: pointer;
}


body.company .App .footer {
  background-color: #212322;
}
body.noheadfixfoot .App .main {
  padding-bottom: 0;
  min-height: auto;
  padding-top: 50px;
}

@media only screen and (max-width: 768px) {
  .App .nav-links,
  .App .nav-right {
    display: none;
  }
  .mob-nav-bar,
  .mob-nav-right {
    display: block;
  }
}